<template>
  <base-dropdown
      menu-on-right
      class="nav-item"
      tag="li"
      title-tag="a"
      menu-classes="dropdown-menu dropdown-actions dropdown-menu-right py-0 overflow-hidden"
  >
    <a
        class="nav-link text-white"
        slot="title"
        href="#"
        data-toggle="dropdown"
        @click.prevent
    >
      <i class="ni ni-ungroup"></i>
    </a>

    <div class="dropdown-item bg-default dropdown-actions">
      <div class="d-flex ml--2">
        <a :href="siteUrl" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-0" style="background: #8965e02b">
                  <b-icon-tags scale="1.5" class="text-purple"/>
                </span> <br>
          <small>АНЭКС</small>
        </a>

        <a :href="journalUrl" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-0" style="background: rgba(17,205,239,0.17)">
                  <b-icon-file-earmark scale="1.5" class="text-info"/>
                </span> <br>
          <small>Экстернат</small>
        </a>

        <a href="http://spbo21.ru" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-0" style="background: rgba(45,206,137,0.17)">
                  <b-icon-building scale="1.5" class="text-green"/>
                </span> <br>
          <small>XXI</small>
        </a>

        <a href="https://pedagogika-online.ru" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-0" style="background: rgba(243,164,181,0.17)">
                  <b-icon-paperclip scale="1.5" class="text-pink"/>
                </span> <br>
          <small>Педагогика</small>
        </a>

      </div>
    </div>

  </base-dropdown>
</template>

<script>
export default {
  name: "ActionsUser",
  computed: {
    siteUrl() {
      return process.env.VUE_APP_JOOMLA_SITE_URL;
    },
    journalUrl() {
      return process.env.VUE_APP_JOOMLA_JOURNAL_URL;
    }
  },
}
</script>

<style scoped>

</style>