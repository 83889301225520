<template>
  <div>
    <base-header type="gradient-indigo" class="pb-6 pb-6 pt-5 pt-md-5" />
    <span class="mask bg-gradient-indigo" />
    <div class="container-fluid mt-5">

      <b-card
          class="mb-4 col-3"
      >
        <b-row>
          <b-col cols="1" class="my-2 mr-3">
            <b-icon-search font-scale="1.2"/>
          </b-col>
          <b-col class="d-flex">
              <b-form-checkbox
                  v-model="filters.errorsOnly"
                  @change="fetchDataWithFilters"
                  class="p-1 ml-5 mt-1 rounded-lg bg-white"
              >
                <span class="ml--3"> Только ошибки </span>
              </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>

      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <template v-if="!isLoading" >
          <div class="table-responsive">
            <base-table
                class="table align-items-center table-flush"
                tbody-classes="list"
                :data="items"
                :thead-classes="'thead-light'"
            >
              <template slot="columns">
                <th>Метод</th>
                <th>Путь</th>
                <th>Статус</th>
                <th></th>
                <th>Память</th>
                <th>Время</th>
                <th>IP</th>
                <th>Ответ</th>
              </template>

              <template slot-scope="{ row }">
                <td><h3><b-badge :variant="methodVariant(row.content.method)">
                  {{ row.content.method }}
                </b-badge></h3></td>
                <td>
                  {{ row.content.uri }}
                </td>
                <td><h3>
                  <b-badge
                      v-b-modal.modal-response
                      :variant="statusVariant(row.content.response_status)"
                      @click="showResponse(row.content.response)"
                      class="pointer"
                  >
                    {{ row.content.response_status }}
                  </b-badge>
                </h3></td>
                <td><i>
                  {{ row.content.duration }} мс
                </i></td>
                <td>
                  {{ row.content.memory }} МБ
                </td>
                <td>
                  {{ row.created_at }}
                </td>
                <td><h2><b-badge class="bg-dark text-white">
                  {{ row.content.ip_address }}
                </b-badge></h2></td>
                <td>
                  <b-btn
                      v-b-modal.modal-response
                      @click="showResponse(row.content.response)"
                  >
                    ...
                  </b-btn>
                </td>
              </template>
            </base-table>
          </div>
        </template>
        <template v-else>
          <b-skeleton-table
              :rows="20"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <div
            v-if="itemsPagination.last_page > 1"
            class="card-footer d-flex justify-content-end"
        >
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
          />

        </div>
      </card>

      <b-modal
          centered
          id="modal-response"
          hide-header
          hide-footer
          body-bg-variant="default"
          size="lg"
      >
        <pre class="text-white">{{ response }}</pre>
      </b-modal>

    </div>
  </div>
</template>
<script>
import TelescopeEntryResource from "../../../resources/telescope/telescope-entry";

export default {
  name: "requestsList",
  data() {
    return {
      items: [],
      itemsPagination: {},
      filters: {
        type: 'request',
        errorsOnly: false,
      },
      response: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    async fetchData(page, perPage) {
      this.fetch(TelescopeEntryResource, this.filters, page, perPage);
    },
    statusVariant(status) {
      switch (status) {
        case 200:
        case 204:
          return 'success';

        case 422:
          return 'light';

        default:
          return 'danger';
      }
    },
    methodVariant(method) {
      switch (method) {
        case 'POST':
          return 'primary';
        case 'GET':
          return 'info';
        default:
          return 'info';
      }
    },
    showResponse(response) {
      this.response = response;
    },
  },
};
</script>
<style>
</style>
