<template>
  <div class="row justify-content-center mt-md-6">
    <div class="col-md-8 col-sm-12">
      <div class="card bg-white shadow border-0">
        <div class="card-body px-lg-5">

          <b-row>
            <b-col class="border-right pr-4 d-none d-md-block bg-secondary">
              <h1>Вход</h1>
              <p class="text-lead">
                Внимание! Если вы еще не регистрировались в этом новом личном кабинете,
                вам надо пройти регистрацию заново. Не волнуйтесь, все ваши сертификаты
                и услуги, заказанные ранее, будут доступны в течение 10 лет
                в старом личном кабинете по адресу: <a href="https://old.aneks.center/lk"> old.aneks.center/lk </a> При возникновении любых проблем звоните 8-921-956-67-42
              </p>
            </b-col>
            <b-col class="pl-4">
              <div class="text-xl text-center font-weight-bolder text-indigo pb-3">
                <!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
                АНЭКС
              </div>

              <template v-if="$store.getters.authErrorCode === 500">
                <b-alert show variant="danger">
                  Неверные данные для входа.
                </b-alert>
              </template>

              <router-link to="/register" v-if="$store.getters.authErrorCode === 531">
                <b-alert show variant="danger">
                  Пользователь не найден. <span class="dotted"> Пожалуйста, пройдите регистрацию.</span>
                </b-alert>
              </router-link>

              <router-link to="/password/reset" v-if="$store.getters.authErrorCode === 532">
                <b-alert show variant="danger">
                  Неверный пароль. <br><span class="dotted">Забыли пароль?</span>
                </b-alert>
              </router-link>

              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                    alternative
                    class="input-group-alternative mb-3"
                    placeholder="Телефон или почта"
                    addon-left-icon="ni ni-single-02"
                    v-model="user.email"
                    name="Email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <base-input
                    alternative
                    class="input-group-alternative"
                    placeholder="Пароль"
                    :type="!isShowPassword ? 'password' : ''"
                    addon-left-icon="ni ni-key-25"
                    addon-right-icon="ni ni-glasses-2 text-dark"
                    tooltip-right-icon="Показать пароль"
                    @click-right="isShowPassword = !isShowPassword"
                    v-model="user.password"
                    name="Password"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <!--      TODO      -->
                <b-col>
                  <base-checkbox class="ml--2">
                    <span class="ml--3">Запомнить меня</span>
                  </base-checkbox>
                </b-col>
                <b-col>

                </b-col>
                <div class="text-center">
                  <base-button
                      native-type="submit"
                      class="btn-login my-4 bg-purple border-0"
                  >
                    Вход
                  </base-button>
                </div>
              </form>
            </b-col>
          </b-row>

        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <router-link to="/password/reset" class="text-light text-lg">
            <small>
              Забыли пароль?
            </small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light text-lg">
            <small>
              Регистрация
            </small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  name: "login",
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      isShowPassword: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      const user = this.user;
      await this.$store.dispatch("login", { user });
    },
  },
};
</script>

<style>
.input-group-alternative .form-control {
  padding-left: 0.5rem !important;
}
</style>
