<template>
  <div>
    <base-header type="gradient-indigo" class="pt-5 pt-md-5"/>
    <span class="mask bg-primary"/>
    <div class="container-fluid mt-5 publication-managment">
      <card body-classes="px-0" class="border-0 shadow-none card-publication">
        <b-btn @click="publishAccepted" size="sm" variant="default" class="float-right mb-3 mt--2 mr-4" v-b-tooltip="'Опубликовать одобренные интернет-публикации'">
          Опубликовать одобренные
        </b-btn>
        <!--    TODO Переключение вида    -->
        <publication-table
            :loading="isLoading"
            :items="items"
            :items-pagination="itemsPagination"
            :selected-items="selectedIds"
            @on-update="fetchData"
            @collect="collect"
        />
      </card>
    </div>

    <div v-if="selectedIds.length && selectedIds.length > 0" class="container-fluid mt-3 publication-managment">
      <card body-classes="px-4" class="border-0 shadow-none card-publication">
        <h4 class="font-weight-bold">Добавление в сборник</h4>
        <h5 class="font-weight-500">Выбрано: {{ selectedIds.length }}</h5>
        <b-input placeholder="Ссылка на сборник" v-model="url" />
        <b-button class="float-right mt-3" variant="primary" @click="addUrl" pill>
          Сохранить
        </b-button>
      </card>
    </div>
  </div>
</template>
<script>

import PublicationResource from "../../../resources/publication";
import PublicationList from "../../shared/Publication/PublicationList";
import PublicationTable from "./PublicationTable.vue";

export default {
  name: "PublicationManagement",
  components: {
    // PublicationList,
    PublicationTable
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
      selectedIds: [],
      url: '',
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, filters, perPage) {
      this.fetch(PublicationResource, filters, page, perPage);
    },
    /**
     * Публикация одобренных интернет-публикаций
     */
    async publishAccepted() {
      const value = await this.$bvModal.msgBoxConfirm(
          'Вы уверены, что хотите опубликовать все одобренные интернет-публикации?', {
            title: 'Публикация всех одобренных заявок',
            size: 'sm',
            okTitle: 'ДА',
            cancelTitle: 'НЕТ',
            hideHeaderClose: false,
            centered: true,
          })
      if (value) {
        await PublicationResource.publishAccepted();
        this.$notify({
          type: "success",
          message: "Одобренные публикации успешно опубликованы!",
        });
        await this.fetchData();
      }
    },
    /**
     * Добавление публикации в коллекцию
     * @param item
     */
    collect(item) {
      if (!this.selectedIds.includes(item.id))
        this.selectedIds.push(item.id);
      else
        this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);
      console.log(this.selectedIds);
    },
    /**
     * Изменение url в массиве выбранных публикаций
     */
    async addUrl() {
      await PublicationResource.addUrl({
        ids: this.selectedIds,
        url: this.url,
      });
      this.$notify({
        type: "success",
        message: "Выбранные публикации успешно обновлены!",
      });
      await this.fetchData();
    }
  },
};
</script>
<style>
.publication-managment .card-footer {
  background: transparent;
}
</style>
