<template>
  <div>
    <b-card class="mb-4 ml--3 col-10 rounded-0 card-publication border-0">
      <b-row>
        <b-col cols="1" class="my-2 mr--3">
          <b-icon-search font-scale="1.2"/>
        </b-col>
        <b-col>
          <b-input v-model="filters.entryNumber" placeholder="№ заявки" @change="changePageWithFilters" />
        </b-col>
        <b-col cols="2" v-if="isManager">
          <b-input v-model="filters.fio" placeholder="ФИО" @change="changePageWithFilters" />
        </b-col>
        <b-col v-if="!hideService" cols="2">
          <l-select
              :options="services"
              placeholder-text="Тип услуги"
              v-model="filters.serviceId"
              @input="changePageWithFilters"
              class="overflow-clip text-nowrap"
              add-default-option
          />
        </b-col>
        <b-col cols="2">
          <b-input v-model="filters.title" placeholder="Заголовок" @change="changePageWithFilters" />
        </b-col>
        <b-col>
          <l-select
              :options="statuses"
              placeholder-text="Статус"
              v-model="filters.status"
              @input="changePageWithFilters"
              class="overflow-clip text-nowrap"
              add-default-option
          />
        </b-col>
        <b-col>
          <l-select
              :options="entryStatuses"
              placeholder-text="Оплата"
              v-model="filters.entryStatus"
              @input="changePageWithFilters"
              class="overflow-clip text-nowrap"
              add-default-option
          />
        </b-col>
      </b-row>
    </b-card>

    <div class="user-dashboard">
      <b-row v-if="!loading">
        <template v-for="row in items">
        <module
            v-if="row.service"
            :class="{'opacity-7': row.status === statusRejected}"
            @on-click="viewItem(row)"
            :subtitle="row.service.title"
            :title="cutText(row.title, 34)"
            class="module card-publication pointer mb-4"

            :key="'publication' + row.id"
        >
          <template v-slot:buttons>
            <a
                @click.stop
                v-b-tooltip="'Просмотреть'"
                class="btn btn-secondary"
                v-if="row.url"
                :href="row.url"
                target="_blank"
            >
              <b-icon-box-arrow-up-right scale="0.9"/>
            </a>
            <a
                @click.stop="removeItem(row)"
                v-b-tooltip="'Удалить'"
                class="btn btn-danger text-white"
                v-if="row.status === statusRejected"
            >
              <b-icon-trash-fill scale="0.9"/>
            </a>
          </template>

          <div class="d-flex mb-2 mt--2 ticketItem-book">
            <h4 class="mr-2">
              <b-badge :variant="row.statusColor">
                {{ row.statusLabel }}
              </b-badge>
            </h4>
            {{ row.created_at_formatted }}
            <b class="ml-2">{{ row.service.price }} ₽</b>
          </div>

          <div v-for="entry in row.entries" :key="'entry' + entry.number" class="dashed py-3">
            <b> {{ entry.number }} </b>
            {{ entry.user.fio }} <br>
            <b-badge :variant="entry.statusColor" class="bg-white">
              {{ entry.statusLabel }}
            </b-badge>
          </div>

          <div v-if="row.manager_comment && (row.status === statusDeclined || row.status === statusRejected)" class="mt-3">
            <b class="mr-2">Комментарий:</b>
            <small>{{ row.manager_comment }}</small>
          </div>

          <div v-else-if="row.text && row.file === null" class="mt-3">
            <a @click.stop class="mt--3 mb-2 text-sm" :href="row.url" target="_blank" v-if="row.url">
              <b-icon-link45deg />
              Просмотреть на сайте
            </a>
            <small v-html="cutText(row.text, 300)" />
          </div>

          <div v-else-if="row.file" class="mt-3 text-center">
            <a @click.stop class="mt--3 mb-2 text-sm" :href="row.url" target="_blank" v-if="row.url">
              <b-icon-link45deg />
              Просмотреть на сайте
            </a>
<!--            <b-icon-file-earmark font-scale="2" class="h1 mt-5 ml&#45;&#45;4" />-->
            <a-file
                text-class="text-left ml-4 mt-1"
                :file="row.file"
                is-short-text
                hide-delete-btn
                text-size="md"
                icon-margin-left="1"
            />
          </div>

<!--          <template v-slot:footer>-->
<!--            <b-btn variant="outline-primary" class="mt-3" @click="viewItem(row)" v-b-tooltip="'Просмотреть'">-->
<!--              <b-icon-eye-fill />-->
<!--            </b-btn>-->
<!--          </template>-->

        </module>
        </template>
      </b-row>

      <b-row v-else>
        <module
            class="module card-publication pointer mb-4"
            v-for="row in [0, 1, 2, 3, 4, 5]"
            :key="`skeleton-${row}`"
        >
          <template v-slot:header>
            <b-skeleton width="90%" />
          </template>
          <div class="mb-2 mt--2 ticketItem-book">
            <b-skeleton width="75%" />
            <b-skeleton width="85%" />
            <b-skeleton width="70%" />
            <b-skeleton-img class="mt-4" Jnlt/>
          </div>
        </module>
      </b-row>
    </div>

    <publication-view-modal
        :publication="selected"
        @on-update="changePage"
    />

    <div
        v-if="itemsPagination.last_page > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          :page-count="itemsPagination.last_page"
          :value="itemsPagination.current_page"
          @step="changePage"
      />

    </div>
  </div>
</template>

<script>
import ServiceResource from "../../../resources/service";
import PublicationResource from "../../../resources/publication";
import PublicationViewModal from "../../manager/Publication/PublicationViewModal";
import Module from "../../user/Dashboard/Modules/Module";

export default {
  name: "PublicationList",
  components: {
    Module,
    PublicationViewModal,
  },
  data() {
    return {
      selected: {
        text: ''
      },
      filters: {
        title: null,
        entryNumber: null,
        fio: null,
        serviceId: null,
        status: 1,
        entryStatus: null
      },
      services: [],
      entryStatuses: [
        { value: 1, label: 'Не оплачена' },
        { value: 2, label: 'Оплачена' },
        { value: 3, label: 'Завершена' },
      ],
      statuses: [
        { value: 1, label: 'Ожидает проверки' },
        { value: 2, label: 'Одобрена' },
        { value: 3, label: 'Требует доработок' },
        { value: 5, label: 'Взято в журнал' },
        { value: 4, label: 'Опубликована' },
        { value: 0, label: 'Отклонена' },
      ],
      statusRejected: 0,
      statusPending: 1,
      statusDeclined: 3,
    }
  },
  props: {
    items: {
      type: Array,
    },
    itemsPagination: {
      type: Object,
    },
    hideService: {
      type: Boolean,
      default() {
        return false;
      }
    },
    loading: {
      type: Boolean,
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.services = await ServiceResource.fetch({
        isPublication: true
      });
      this.services = this.services.map((elm) => ({
        ...elm,
        value: elm.id,
        label: elm.title,
      }));
    },
    /**
     * Удаляет из текста тэги и сокращает его до 300 символов
     * @param text
     * @returns {string}
     */
    cutText(text, length) {
      const cut = text.substr(0, length);
      let end = '';
      if (cut.length !== text.length) end = '...';
      return `${cut}${end}`;
    },
    async remove(item) {
      await this.deleteItem(PublicationResource, item.id);
      this.$emit('on-update', this.itemsPagination.current_page);
    },
    viewItem(item) {
      this.selected = item;
      this.$bvModal.show('publicationViewModal' );
    },
    async removeItem(item) {
      await this.deleteItem(PublicationResource, item.id);
      this.changePage();
    },
  },
}
</script>

<style scoped>

</style>