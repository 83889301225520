<template>
  <div>
    <base-header type="gradient-indigo" class="pb-6 pb-6 pt-0 pt-md-5 mt--5 mt-md-0"/>
    <div class="container-fluid mt-0 col-11">

      <b-alert :show="hasEntriesWaitingForTheses" variant="warning" class="mb-3">
        <p class="text-white mb--1">
          Некоторые заявки ожидают добавления тезисов! <br>
          Нажмите кнопку <span class="p-2 text-lg"> + <i class="fa fa-tag" /> </span>
          на заявке, чтобы добавить тезисы.
        </p>
      </b-alert>

      <!--   TODO Объединить под один mixin!   -->
      <card body-classes="px-0 pb-1" footer-classes="pb-2" v-if="!alt">
        <entry-list
            hide-fio
            :loading="isLoading"
            :entries="items"
            :entries-pagination="itemsPagination"
            @on-update="fetchData"
            btn-clear-filters-class="mt--3 mb-2"
        />

      </card>
      <card body-classes="px-0 pb-1" footer-classes="pb-2" v-else>
        <entry-list-mobile
            hide-fio
             :loading="isLoading"
            :entries="items"
            :entries-pagination="itemsPagination"
            @on-update="fetchData"
        />

      </card>

    </div>
  </div>
</template>
<script>
import EntryResource from "../../../resources/entry";
import EntryList from "../../shared/Entry/EntryList";
import EntryListMobile from "../../shared/Entry/EntryListMobile.vue";

export default {
  name: "EntryResource",
  components: {
    EntryList,
    EntryListMobile
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
      selectedEntry: null,
      alt: false,
    };
  },
  created() {
    const onResize = () => {
      // this.alt = window.innerWidth <= 991;
      this.alt = window.innerWidth <= 1199;
    };
    onResize();
    window.addEventListener('resize', onResize);
    this.$on('hook:beforeDestroy', () => window.removeEventListener('resize', onResize));
  },

  async mounted() {
    await this.fetchData();
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    /** Количество заявок, ожидающих тезисов */
    hasEntriesWaitingForTheses() {
      const entriesWithThesisNeeded = this.items.filter(
          item => item.is_thesis_needed
      );
      const entriesWaitingForThesis = entriesWithThesisNeeded.filter(
          item => item.thesis === null
      )
      return entriesWaitingForThesis.length > 0;
    },
  },
  methods: {
    async fetchData(page, filters, perPage) {
      if (filters === undefined) filters = {};
      await this.$store.dispatch('me');
      if (this.$store.getters.user.id) {
        filters.created_by = this.$store.getters.user.id;
        this.fetch(EntryResource, filters, page, perPage)
      }
      await this.$store.dispatch('me');
    },
  },
};
</script>
<style>
</style>
