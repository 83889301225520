<template>
  <div>
    <base-header type="default" class="pb-6 pb-6 pt-5 pt-md-5" />
    <div class="container-fluid mt-5 w-75">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <b-btn variant="primary" pill class="float-right" @click="editItem({})">
                + Добавить
              </b-btn>
              <h3 class="mb-0">Выпуски журнала</h3>
            </div>
          </div>
        </div>

        <template v-if="!isLoading">
          <div class="table-responsive">
            <base-table
                class="table align-items-center table-flush"
                tbody-classes="list"
                :data="items"
                :thead-classes="'thead-light'"
            >
              <template slot="columns">
                <th>Категория</th>
                <th>Название</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td class="text-wrap" :class="{'text-muted': row.is_published}">
                  <i class="ni ni-single-copy-04 mr-2" :class="{'text-primary': !row.is_published}" />
                  {{ $store.getters.getDictItemById('dict_service_subtypes', row.dict_service_subtype_id).fdv }}
                </td>
                <td class="text-wrap font-weight-bold" :class="{'text-muted': row.is_published}">
                  {{ row.title }}
                </td>
                <td class="text-center">
                  <b-btn
                      tag="a"
                      :variant="row.is_published ? 'link' : 'outline-primary'"
                      @click="editItem(row)"
                      class="mr-2"
                      :disabled="!!row.is_published"
                  >
                    <b-icon-pencil-fill />
                  </b-btn>
<!--                  <b-btn tag="a" variant="outline-danger" @click="remove(row)">-->
<!--                    <i class="fas fa-trash" />-->
<!--                  </b-btn>-->
                  <b-btn
                      tag="a"
                      variant="success"
                      v-b-tooltip="'Опубликовать'"
                      @click="publish(row)"
                      v-if="!row.is_published"
                  >
                    <b-icon-check />
                  </b-btn>
                  <b-btn tag="a" variant="link" @click="unpublish(row)" v-else>
                    <b-icon-arrow-counterclockwise />
                  </b-btn>
                </td>
              </template>
            </base-table>
          </div>
        </template>
        <template v-else>
          <b-skeleton-table
              :rows="20"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <div class="card-footer d-flex justify-content-end" v-if="itemsPagination">
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
          />
        </div>
      </card>
    </div>
    <item-forms-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import ItemFormsModal from './PublicationRelease/ItemFormsModal';
import PublicationReleaseResource from "../../../resources/publication-release";

export default {
  name: "PublicationReleaseList",
  components: {
    ItemFormsModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
      itemsPagination: null
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, perPage) {
      this.fetch(PublicationReleaseResource, {}, page, perPage)
    },
    async remove(item) {
      await this.deleteItem(PublicationReleaseResource, item.id);
      await this.fetchData();
    },
    async publish(item) {
      await PublicationReleaseResource.publish(item.id);
      await this.fetchData();
    },
    async unpublish(item) {
      item.is_published = false;
      await PublicationReleaseResource.save(item);
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('itemFormsModal');
    }
  },
};
</script>
<style>
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
