<template>
  <div>
    <base-header type="default" class="pb-6 pb-6 pt-5 pt-md-5" />
    <div class="container-fluid mt-5 w-75">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Цены публикаций</h3>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <base-table
            class="table align-items-center table-flush"
            tbody-classes="list"
            :data="items"
            :thead-classes="'thead-light'"
          >
            <template slot="columns">
              <th>#</th>
              <th>Тип</th>
              <th>Кол-во авторов</th>
              <th>Цена</th>
              <th></th>
            </template>

            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td class="text-wrap">{{ $store.getters.getDictItemById('dict_service_subtypes', row.dict_service_subtype_id).fdv }}</td>
              <td class="text-wrap">{{ row.authors_num }} автор(-а)</td>
              <td class="text-wrap">{{ row.price }} руб.</td>
              <td>
                <b-btn tag="a" variant="outline-primary" @click="editItem(row)" class="mr-2 pointer">
                  <b-icon-pencil-fill scale="1.3" />
                </b-btn>
<!--                <b-btn tag="a" variant="outline-danger" @click="remove(row)" disabled>-->
<!--                  <i class="fas fa-trash" />-->
<!--                </b-btn>-->
              </td>
            </template>
          </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
<!--          <base-pagination></base-pagination>-->
<!--          <base-pagination total="1"></base-pagination>-->
        </div>
      </card>
    </div>
    <item-forms-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import ItemFormsModal from './PublicationPrice/ItemFormsModal';
import PublicationPriceResource from "../../../resources/publication-price";

export default {
  components: {
    ItemFormsModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.items = await PublicationPriceResource.fetch();
    },
    async remove(item) {
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('itemFormsModal');
    }
  },
};
</script>
<style>
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
