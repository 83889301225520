<template>
  <div>
    <base-header type="default" class="pb-6 pb-6 pt-5 pt-md-5" />

    <div class="container-fluid mt-5 w-75">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <b-btn variant="primary" pill class="float-right" @click="editItem({})">
                + Добавить
              </b-btn>
              <h3 class="mb-0">Ведущие</h3>
            </div>
          </div>
        </div>

        <template v-if="!isLoading">
          <div class="table-responsive">
            <base-table
                class="table align-items-center table-flush"
                tbody-classes="list"
                :data="items"
                :thead-classes="'thead-light'"
            >
              <template slot="columns">
                <th>ФИО</th>
                <th>Описание</th>
                <th>URL</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td class="col-2">{{ row.fio }}</td>
                <td class="col-6 text-wrap">{{ row.description }}</td>
                <td class="col-4 text-wrap"><a :href="row.url" target="_blank">
                  {{ row.url }}
                </a></td>
                <td>
                  <b-btn tag="a" variant="outline-primary" @click="editItem(row)" class="mr-2 pointer">
                    <b-icon-pencil-fill scale="1.3" />
                  </b-btn>
                  <b-btn tag="a" variant="outline-danger" @click="remove(row)">
                    <i class="fas fa-trash" />
                  </b-btn>
                </td>
              </template>
            </base-table>
          </div>
        </template>
        <template v-else>
          <b-skeleton-table
              :rows="20"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <div class="card-footer d-flex justify-content-end" v-if="itemsPagination">
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
          />
        </div>

      </card>
    </div>

    <leading-forms-modal :item="selectedItem" @on-update="fetchData" />

  </div>
</template>
<script>
import LeadingResource from "../../../resources/leading";
import LeadingFormsModal from "./Leading/LeadingFormsModal";

export default {
  name: "LeadingList",
  components: {
    LeadingFormsModal
  },
  data() {
    return {
      items: [],
      itemsPagination: null,
      selectedItem: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page = undefined, perPage = undefined) {
      await this.fetch(LeadingResource, {}, page, perPage);
    },
    async remove(item) {
      await this.deleteItem(LeadingResource, item.id);
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('leadingFormsModal');
    }
  },
};
</script>