<template>
  <div class="wrapper bg-default" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      id="sidenav-admin"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Главная',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/manager/dashboard',
          }"
        />

        <sidebar-item
            :link="{
            name: `Заявки`,
            icon: 'ni ni-send text-yellow',
            path: '/manager/entry',
          }"
        />

        <sidebar-item
            :link="{
            html: `Публикации ${spanPending}`,
            icon: 'ni ni-align-center text-red',
            path: '/manager/publication',
          }"
        />

        <sidebar-item
            :link="{
            name: 'Услуги',
            icon: 'ni ni-archive-2 text-pink',
            path: '/manager/service',
          }"
        />

        <sidebar-item
            :link="{
            name: 'Пакеты услуг',
            icon: 'ni ni-app text-purple',
            path: '/manager/service-package',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Пользователи',
            icon: 'ni ni-single-02 text-info',
            path: '/manager/users',
          }"
        />

        <sidebar-item
            :link="{
              name: 'Платежи',
              icon: 'ni ni-money-coins text-cyan',
            }"
        >
          <sidebar-item
              :link="{
                name: 'Платежи',
                path: '/manager/payments',
              }"
          />
          <sidebar-item
              :link="{
                name: 'ЮКасса',
                path: '/manager/yookassa',
              }"
          />
        </sidebar-item>

        <sidebar-item
            :link="{
              name: 'Шаблоны',
              icon: 'ni ni-single-copy-04',
            }"
        >
          <sidebar-item
              :link="{
                name: 'Макросы',
                path: '/manager/macroses',
              }"
          />

          <sidebar-item
              :link="{
                name: 'Шаблоны',
                path: '/manager/templates'
            }"
          />

          <sidebar-item
              :link="{
                name: 'События',
                path: '/manager/events',
              }"
          />

        </sidebar-item>

        <sidebar-item
            :link="{
              name: 'Справочники',
              icon: 'ni ni-briefcase-24 text-orange',
            }"
        >
          <sidebar-item
              :link="{
                name: 'Ведущие',
                path: '/manager/leadings'
            }"
          />
          <sidebar-item
              :link="{
                name: 'Типы услуг',
                path: '/manager/service-types'
            }"
          />
          <sidebar-item
              :link="{
                name: 'Профили',
                path: '/manager/profiles',
              }"
          />
          <sidebar-item
              :link="{
                name: 'Рубрики',
                path: '/manager/publication-categories',
              }"
          />
          <sidebar-item
              :link="{
                name: 'Выпуски',
                path: '/manager/publication-releases',
              }"
          />
          <sidebar-item
              :link="{
                name: 'Цены публикаций',
                path: '/manager/publication-prices'
            }"
          />
<!--          <sidebar-item-->
<!--              :link="{-->
<!--                name: 'Регионы',-->
<!--                path: '/manager/regions',-->
<!--              }"-->
<!--          />-->
        </sidebar-item>

        <sidebar-item
            :link="{
              name: 'Разное',
              icon: 'ni ni-settings-gear-65 text-gray',
            }"
        >
<!--          <sidebar-item-->
<!--              :link="{-->
<!--                name: 'Запросы API',-->
<!--                path: '/manager/api-requests'-->
<!--            }"-->
<!--          />-->

          <sidebar-item
              :link="{
                name: 'Иконки',
                path: '/manager/icons',
              }"
          />

<!--          <sidebar-item-->
<!--              :link="{-->
<!--                name: 'Рассылка',-->
<!--                path: '/manager/mailing',-->
<!--              }"-->
<!--          />-->

          <sidebar-item
              :link="{
                name: 'Отзывы',
                path: '/manager/reviews',
              }"
          />

        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer class="bg-transparent" />
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./NavbarHead.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    spanPending() {
      const pending = this.$store.getters.getStatisticsByName('publicationPending');
      const classes = 'bg-gradient-danger rounded-circle text-xs text-white ml-2 pl-1 pr-1';
      return pending > 0 ?
          `<span class='${classes}'>${pending}</span>` :
          '';
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">

</style>
