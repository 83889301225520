<template>
  <div>
    <base-header type="gradient-indigo" class="pt-5 pt-md-5"/>
    <span class="mask bg-dark" />

    <div class="container-fluid mt-5">
      <payment />
    </div>
  </div>
</template>
<script>
import Payment from "./Payment/Payment";

export default {
  name: "PaymentMain",
  components: {
    Payment,
  },
};
</script>
<style>
</style>