<template>
  <b-modal
      id="serviceEntriesModal"
      size="xl"
      modal-class="xxl"
      hide-footer
      :title="service.title"
  >
    <b-row>
      <b-col v-if="!isLoadingGuests" class="publication">
        <h2 class="ml-2">Гости</h2>
        <b-list-group-item v-for="guest in guests" :key="guest.id" class="mb-2 text-xs">
          <template v-if="guest">
            <a class="float-right pointer" @click="addGuest(guest)">
              <b-icon-plus-circle-fill font-scale="1.5" />
            </a>
            {{ guest.fio }} <br>
            <b-badge variant="primary" class="p-1 mt-1 text-wrap text-left">
              <b-icon-bank class="pb-1" font-scale="1.5" /> {{ guest.organization }}
            </b-badge>
            <b-badge variant="warning" class="p-1 mt-1 text-wrap text-left">
              <b-icon-person-fill /> {{ guest.job_title }}
            </b-badge>
          </template>
        </b-list-group-item>
      </b-col>
      <b-col v-else>
        <b-list-group-item v-for="index in [0, 1, 2, 3, 4]" :key="index" class="mb-2">
          <b-skeleton width="70%" />
          <b-skeleton width="50%" />
          <b-skeleton width="80%" />
        </b-list-group-item>
      </b-col>
      <b-col cols="10">
        <h2 class="ml-2">Заявки</h2>
        <entry-list
            show-section
            :loading="isLoading"
            :entries="items"
            :entries-pagination="itemsPagination"
            hide-service
            @on-update="fetchData"
        />
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import EntryList from "../../shared/Entry/EntryList";
import EntryResource from "../../../resources/entry";
import UserResource from "../../../resources/user";
import arrayMixin from "../../../mixins/array-mixin";

export default {
  name: "ServiceEntriesModal",
  components: {
    EntryList
  },
  mixins: [
    arrayMixin
  ],
  data() {
    return {
      items: [],
      itemsPagination: {},
      guests: [],
      isLoadingGuests: false,
      numberOfGuestsToLoad: 50,
    };
  },
  props: {
    service: {
      type: Object,
    },
  },
  watch: {
    service: {
      async handler() {
        await this.fetchGuests();
        await this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchData(
        page = undefined,
        filters = {},
        perPage = undefined
    ) {
      filters.service_id = this.service.id;
      this.fetch(EntryResource, filters, page, perPage);
    },
    async fetchGuests() {
      this.isLoadingGuests = true;
      const roleGuest = 3;
      const profiles = this.service.profiles.map(e => e.id);
      this.guests = await UserResource.fetch({
        role: roleGuest,
        withoutParticipation: this.service.id,
        profile: profiles,
      }, 1, this.numberOfGuestsToLoad);
      this.isLoadingGuests = false;
    },
    async addGuest(guest) {
      this.replaceArrayElm(guest, this.guests);
      await this.sendGuestEntry(guest);
      await this.fetchData(this.itemsPagination.current_page);
    },
    /**
     * Отправляет заявку от лица гостя
     * со статусом 'завершено'
     * @param guest
     * @returns {Promise<void>}
     */
    async sendGuestEntry(guest) {
      const statusDone = 3;
      const payTypeOnline = 3;
      let sectionId = null;
      if (this.service.sections.length > 0) {
        const sortedSections = this.service.sections.sort((a, b) => {
          if (a.participants_num < b.participants_num) return -1;
          if (a.participants_num > b.participants_num) return 1;
          return 0;
        });
        sectionId = sortedSections[0].id;
      }
      await EntryResource.save({
        created_by: guest.id,
        service_id: this.service.id,
        section_id: sectionId,
        pay_type: payTypeOnline,
        status: statusDone
      });
    },
  },
}
</script>

<style scoped>

</style>