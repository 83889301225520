<template>
  <div>
    <div class="table-responsive px-lg-5">
      <base-table
          :loading="loading"
          class="table align-items-center table-flush px-5"
          tbody-classes="list"
          :data="items"
          :thead-classes="'thead-light'"
          :skeleton-columns="[1, 2, 3, 4, 5, 6]"
      >
        <template slot="columns">
<!--          <th class="text-center">№</th>-->
          <th class="text-center col-3">UUID</th>
          <th class="text-center col-1">Заказ, #</th>
          <th class="text-center col-2">Покупатель</th>
          <th class="text-center col-2">Услуга / Комментарий</th>
          <th class="text-center col-1">Сумма</th>
<!--          <th class="text-center">Статус</th>-->
          <th class="text-center col-1">Дата</th>
        </template>

        <template slot="filters">
          <th>
            <l-select
                :options="statuses"
                placeholder-text="..."
                v-model="filters.status"
                @input="changePageWithFilters"
                class="overflow-hidden w-75 ml-5"
                add-default-option
            />
            <b-input v-model="filters.uuid" placeholder="UUID" @change="changePageWithFilters" class="overflow-hidden w-75 ml-5 mt-2" />
          </th>
          <th class="text-center col-1">
            <b-input v-model="filters.entry_number" placeholder="№ заявки" @change="changePageWithFilters" />
          </th>
          <th class="text-center">
            <b-input v-model="filters.fio" placeholder="ФИО" @input="changePageWithFilters" />
          </th>
          <th class="text-center">
            <l-select
                :options="typesAll"
                placeholder-text="..."
                v-model="filters.serviceType"
                @input="changePageWithFilters"
                class="overflow-hidden mb-2"
                add-default-option
            />
            <b-input v-model="filters.serviceTitle" placeholder="Мероприятие" @input="changePageWithFilters" />
          </th>
          <th class="text-center">
            <b-input v-model="filters.sum" placeholder="Сумма" @change="changePageWithFilters" />
          </th>
<!--          <th class="col-1">-->
<!--            <l-select-->
<!--                :options="statuses"-->
<!--                placeholder-text="..."-->
<!--                v-model="filters.status"-->
<!--                @input="changePageWithFilters"-->
<!--                class="overflow-hidden"-->
<!--                add-default-option-->
<!--            />-->
<!--          </th>-->
          <th></th>
        </template>

        <template slot-scope="{ row }" v-if="row.color">
          <td class="text-center">
            <a :href="`https://yookassa.ru/my/payments?search=${row.uuid}`" target="_blank">
              {{ row.uuid }}
            </a><br>
            <b-badge :variant="row.color">
              {{ row.status_label }}
            </b-badge>
          </td>
          <td class="text-center">
            {{ row.entry_number ? row.entry_number : 'Без заявки' }}
          </td>
          <td class="col-2 text-wrap text-center">
            {{ row.entry_number ? row.entry.user.fio : 'Без авторизации' }}
          </td>
          <td class="col-2 text-wrap text-center">
            <template v-if="row.entry_number">
              {{ row.entry.service.title }}
            </template>
            <template v-else>
              <b-badge variant="dark" class="mb-1">
                Без услуги
              </b-badge>
              <pre class=" text-wrap">
                {{ row.description }}
              </pre>
            </template>
          </td>
          <td class="text-center">
            <template v-if="row.entry_number">
              <b>{{ row.entry.price }} руб.</b>
            </template>
          </td>
<!--          <td class="text-center text-wrap">-->
<!--            <b-badge :variant="row.color">{{ row.status_label }}</b-badge>-->
<!--          </td>-->
          <td class="text-center text-wrap">
            {{ row.created_at_formatted }}
          </td>
        </template>
      </base-table>
    </div>

    <div
        v-if="itemsPagination.last_page > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          :page-count="itemsPagination.last_page"
          :value="itemsPagination.current_page"
          @step="changePage"
      />

    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentList",
  data() {
    return {
      filters: {
        fio: null,
      },
      statuses: [
        { value: 'succeeded', label: 'Подтверждён' },
        { value: 'canceled', label: 'Отменён' },
        { value: 'waiting_for_capture', label: 'Ожидает ответа' },
        { value: 'pending', label: 'В обработке' },
      ],
    };
  },
  props: {
    items: {},
    itemsPagination: {},
    loading: {},
  },
}
</script>

<style scoped>

</style>