<template>
  <div class="container mt-7 pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary border-0 mb-3">
          <div class="card-body px-lg-5 py-lg-4">
            <div class="text-center mb-4">
              <small>Сброс пароля</small>
            </div>

            <form role="form" @submit.prevent="handleSubmit()">
              <router-link to="/register" v-if="emailNotFound">
                <b-alert show variant="danger">
                  Email не найден в текущей системе.
                  <span class="dotted"> Пожалуйста, пройдите регистрацию.</span>
                </b-alert>
              </router-link>
              <a href="https://old.aneks.center/lk/" target="_blank" v-if="emailNotFound">
                <b-alert show variant="warning">
                  <span class="dotted"> Старый личный кабинет </span>
                  (для заказанных раннее услуг)
                </b-alert>
              </a>

              <base-input
                alternative
                class="input-group-alternative mb-3"
                placeholder="Email"
                addon-left-icon="ni ni-email-83"
                v-model="email"
                name="Email"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.email" />

              <div class="text-center">
                <!-- <base-button type="submit" @click.prevent="handleSubmit" class="my-4">Sign in</base-button> -->
                <base-button
                    native-type="submit"
                    class="my-4 btn-login bg-purple border-0"
                >
                  Отправить новый пароль
                </base-button>
              </div>
            </form>
          </div>
        </div>

        <div class="row mt--3">
          <div class="col-6">
            <router-link to="/register" class="text-light text-lg">
              <small>
                Регистрация
              </small>
            </router-link>
          </div>
          <div class="col-6 text-right">
            <router-link to="/login" class="text-light text-lg">
              <small>
                Вход
              </small>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import UserResource from "../../../resources/user";
export default {
  name: "PasswordReset",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      email: "",
      emailNotFound: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await UserResource.resetPassword({ email: this.email });
        await this.$notify({
          type: "success",
          message: "Новый пароль был отправлен на указанный email",
        });
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: "Ошибка! Указанный email не найден в текущей системе.",
          time: 700,
        });
        this.emailNotFound = true;
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>