import axios from "axios";

export default class Resource {

    static backendUrl = `${process.env.VUE_APP_API_BASE_URL}/api`;
    static url;

    static dataPagination;

    static indexPagination = 'meta';
    static indexData = 'data';

    /**
     * Получает все записи
     * @returns {Promise<void>}
     */
    static async fetch(
      params = {},
      paginationPage = undefined,
      perPage = undefined,
    ) {
        if (params == undefined) params = {};
        params['page'] = paginationPage;
        params['per_page'] = perPage;
        const { data } = await axios.get(`${this.backendUrl}/${this.url}`, { params })
        this.dataPagination = data[this.indexPagination];
        return data[this.indexData];
    }

    /**
     * Получает одну запись
     * @param id
     * @returns {Promise<void>}
     */
    static async get(id) {
        const { data } = await axios.get(
            `${this.backendUrl}/${this.url}/${id}`
        );
        return data;

    }

    /**
     * Сохраняет запись
     * @param params
     * @returns {Promise<void>}
     */
    static async save(params, config = {}) {
        if (config === undefined) config = {};
        const isFormData = params instanceof FormData;
        const isNew = params.id == undefined;
        if ((isNew && !isFormData) || (isFormData && !params.has('id'))) {
            return await this.store(params, config);
        } else {
            return await this.update(params, config, isFormData);
        }
    }

    /**
     * Создание новой записи
     * @param params
     * @param config
     * @returns {Promise<any>}
     */
    static async store(params, config) {
        const { data } = await axios.post(
          `${this.backendUrl}/${this.url}`,
          params,
          config
        );
        return data;
    }

    /**
     * Редактирование записи
     * @param params
     * @param config
     * @param isFormData
     * @returns {Promise<*>}
     */
    static async update(params, config, isFormData) {
        const id = isFormData ? params.get('id') : params.id;
        const url = `${this.backendUrl}/${this.url}/${id}`;
        if (isFormData) {
            params.append('_method', 'PUT');
            const { data } = await axios.post(url, params, config);
            return data;
        } else {
            const { data } = await axios.put(url, params, config);
            return data;
        }
    }

    /**
     * Удаляет запись
     * @param id
     * @returns {Promise<void>}
     */
    static async delete(id) {
        const { data } = await axios.delete(
            `${this.backendUrl}/${this.url}/${id}`
        );
        return data;
    }
}