<template>
  <card body-classes="px-0 pb-1" footer-classes="pb-2">
    <div class="card-header pt-0 border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Заявки</h3>
        </div>
      </div>
    </div>

      <entry-list
          :loading="isLoading"
          :entries="items"
          :entries-pagination="itemsPagination"
          @on-update="fetchData"
      />

  </card>
</template>
<script>
import EntryResource from "../../../../resources/entry";
import EntryList from "../../../shared/Entry/EntryList";

export default {
  name: "Entry",
  components: {
    EntryList
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, filters, perPage) {
      this.fetch(EntryResource, filters, page, perPage)
    },
  },
};
</script>
<style>
</style>
