<template>
  <div>
    <base-header type="gradient-indigo" class="pt-5 pt-md-5"/>
    <span class="mask bg-gradient-primary"/>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2" class="bg-transparent border-0 shadow-none">
        <publication-list
            :loading="isLoading"
            :items="items"
            :items-pagination="itemsPagination"
            @on-update="fetchData"
        />
      </card>
    </div>
  </div>
</template>

<script>
import PublicationResource from "../../../resources/publication";
import PublicationList from "../../shared/Publication/PublicationList";

export default {
  name: "UserPublicationList",
  components: {
    PublicationList
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
      statusPending: 1,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, filters, perPage) {
      if (filters === undefined) filters = {};
      filters.userId = this.$store.getters.user.id;
      this.fetch(PublicationResource, filters, page, perPage);
    },
  },
};
</script>
<style>
</style>
