export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async fetch(resourceClass, filters, page, perPage) {
      if (page === undefined) page = 1;
      if (filters === undefined) filters = {};
      this.isLoading = true;
      this.items = await resourceClass.fetch(filters, page, perPage);
      this.itemsPagination = resourceClass.dataPagination;
      this.isLoading = false;
    },
    changePage(page, perPage) {
      this.$emit('on-update', page, this.filters, perPage);
    },
  }
};
